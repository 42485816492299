import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import style from './header.module.scss';
import { Container } from './container';
import { Button } from './button';
import { InfoIcon } from './icons/info';
import { UserContext } from '../context/UserContext';
import { API_ENDPOINT } from '../api';

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "u-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 91) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        className={style.image}
        loading="eager"
        placeholderClassName={style.imagePlaceholder}
        fadeIn={false}
      />
    )}
  />
);

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <Container>
      <Link to="/">
        <HeaderImage />
      </Link>
      <div className={style.title}>
        <h1>{siteTitle}</h1>
      </div>
      <div className={style.pullRight}>
        <UserContext.Consumer>
          {user =>
            user.loggedIn &&
            user.roles.includes('ROLE_ADMIN') && (
              <a href={`${API_ENDPOINT}/api/export/csv`} download>
                <Button modifier={'light'}>Export CSV</Button>
              </a>
            )
          }
        </UserContext.Consumer>
        <Link to="/">
          <Button modifier={'light'} icon={<InfoIcon />}>
            Startseite
          </Button>
        </Link>
        <Link to="/map">
          <Button
            modifier="light"
            icon={
              <img
                src={require('../images/speech-bubbles.png')}
                alt="Sprechblasen"
                style={{
                  margin: '0 13px 0 0',
                  float: 'left'
                }}
              />
            }
          >
            Sprich mit!
          </Button>
        </Link>
      </div>
    </Container>
  </header>
);

export default Header;
