import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styledScrollbar from './styled-scrollbars.module.scss';

export default ({ children, onScroll }) => {
  return (
    <Scrollbars
      onScroll={onScroll}
      className={styledScrollbar.styledScrollbar}
      renderThumbVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            backgroundColor: 'rgba(50,50,50,1)',
            borderRadius: '3px'
          }}
          {...props}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
};
